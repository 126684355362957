<template>
  <div v-if="widget" class="container">
    <div class="leftside">
      <div class="inner-container">
        <form @submit.prevent="handleSubmit">
          <label>Widget Name <i class="fas fa-info-circle" title="Only visible to you" tabindex="0"></i></label>
          <input type="text" v-model="widget.details.name" placeholder="e.g. Website Feedback" required>
          <label>Domain Name <i class="fas fa-info-circle" title="The domain on which your widget will be placed, or the domain your email will be sent from." tabindex="0"></i></label>
          <input type="text" v-model="widget.details.domain" @change="handleDomain" placeholder="e.g. example.com" required>
          <span v-if="domainError" class="error">🤔 Hmm... that doesn't look like a domain.</span>
          <hr>
          <label>Number of Emojis</label><br>
          <span v-if="route.name == 'Edit'" style="font-size: 12px; color: var(--warning);">You can't change the number of emojis of a created widget</span>
          <select v-model="widget.settings.number" :disabled="route.name == 'Edit'" @change="handleEmojiNumber">
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="5">5</option>
          </select>
          <label>Emoji Set <i class="fas fa-info-circle" title="Click on an emoji to change it" tabindex="0"></i></label>
          <div class="emoji-set">
            <div v-for="(emoji, index) in widget.emojis" :key="index" class="emoji-type">
              <img :src="emoji.emojiURL" class="emoji" :data-index="index" @click="handleEmojiClick">
              <span>{{ emoji.type.charAt(0).toUpperCase() + emoji.type.slice(1) }}</span>
            </div>
          </div>
          <span class="btn" @click="handleEmojiFlip">Reverse Order</span><br>
          <label>Embed Type</label>
          <div class="radios">
            <label :class="{ selected: widget.settings.embed == 'float' }"><i class="fas fa-clone"></i> Float<input type="radio" name="embed-type" value="float" checked v-model="widget.settings.embed"></label> 
			      <label :class="{ selected: widget.settings.embed == 'inline' }"><i class="fas fa-bars"></i> Inline<input type="radio" name="embed-type" value="inline" v-model="widget.settings.embed"></label>
          </div>
          <div v-if="widget.settings.embed == 'float'">
            <label>Position</label>
            <select v-model="widget.settings.position">
              <option value="left">Left</option>
              <option value="right">Right</option>
            </select>
          </div>
          <label>Show Widget</label>
          <div class="radios">
            <label :class="{ selected: widget.settings.show == 'automatic' }"><i class="fas fa-magic"></i> Automatically<input type="radio" name="show-type" value="automatic" checked v-model="widget.settings.show"></label> 
			      <label :class="{ selected: widget.settings.show == 'delay' }"><i class="fas fa-stopwatch"></i> After Delay<input type="radio" name="show-type" value="delay" v-model="widget.settings.show"></label>
          </div>
          <div v-if="widget.settings.show == 'delay'">
            <label>Delay Duration</label>
            <select v-model="widget.settings.delay">
              <option value="30">30 Seconds</option>
              <option value="60">1 Minute</option>
              <option value="120">2 Minutes</option>
              <option value="300">5 Minutes</option>
              <option value="600">10 Minutes</option>
              <option value="900">15 Minutes</option>
              <option value="1800">30 Minutes</option>
            </select>
          </div>
          <label>Exclude Pages (optional)</label><br>
          <span style="font-size: 12px;">Exclude all page URLs which contain...</span><br>
          <div v-for="(num, index) in widget.settings.exclPages" :key="index" class="exclude-pages">
            <input type="text" v-model="widget.settings.exclPages[index]" placeholder="e.g. /shop/">
            <span class="btn" title="Remove Field" @click="handlePageRemove(index)">-</span>
          </div>
          <span class="btn" title="Add New Field" @click="handlePageAdd">+</span><br>
          <label>Colours</label>
          <div class="colour-pick">
            <div><span>Main: </span><input type="color" v-model="widget.settings.mainColour"></div>
            <div><span>Text: </span><input type="color" v-model="widget.settings.textColour"></div>
            <div><span>Background (Email & Report): </span><input type="color" v-model="widget.settings.bgColour"></div>
          </div>
          <hr>
          <label>Button Text</label>
          <input type="text" v-model="widget.texts.button" required>
          <label>Widget Title</label>
          <input type="text" v-model="widget.texts.title" required>
          <label>Widget Subtitle (optional)</label>
          <input type="text" v-model="widget.texts.subtitle">
          <label>Submit Button Text</label>
          <input type="text" v-model="widget.texts.submit" required @focus="handleFup">
          <label>Positive Rating Placeholder <i class="fas fa-info-circle" title="The placeholder text to display when a positive rating is selected" tabindex="0"></i></label>
          <input type="text" id="positive-follow-up" required v-model="widget.texts.posQn" @focus="handleFup">
          <label>Neutral / Negative Rating Placeholder <i class="fas fa-info-circle" title="The placeholder text to display when a neutral or a negative rating is selected" tabindex="0"></i></label>
          <input type="text" id="negative-follow-up" required v-model="widget.texts.negQn" @focus="handleFup">
          <label>Thank You Message</label>
          <input type="text" v-model="widget.texts.thanks" required>
          <hr>
          <label><span v-if="profile.billing.plan == 'free-plan'" class="pro-badge" title="Please subscribe to our paid plan to enable this option" tabindex="0">PRO</span> Response Limit <i class="fas fa-info-circle" title="Allow users to vote multiple times, rather than limiting them to one vote every 30 days" tabindex="0"></i></label>
          <div class="radios">
            <label :class="{ selected: widget.settings.multiple, disabled: profile.billing.plan == 'free-plan' }"><i class="fas fa-ban"></i> Disable Limit<input type="checkbox" v-model="widget.settings.multiple" :disabled="profile.billing.plan == 'free-plan'"></label>
          </div>
          <label><span v-if="profile.billing.plan == 'free-plan'" class="pro-badge" title="Please subscribe to our paid plan to enable this option" tabindex="0">PRO</span> Response Voting <i class="fas fa-info-circle" title="Allow users to vote for an existing feedback response, rather than submitting a new one" tabindex="0"></i></label>
          <div class="radios">
            <label :class="{ selected: widget.settings.votes, disabled: profile.billing.plan == 'free-plan' }"><i class="fas fa-vote-yea"></i> Allow Voting<input type="checkbox" id="allow-votes" v-model="widget.settings.votes" :disabled="profile.billing.plan == 'free-plan'" @click="handleFup"></label>
          </div>
          <input type="text" v-if="widget.settings.votes" v-model="widget.texts.votes" placeholder="Text to display" required style="margin-top: -5px;">
          <label><span v-if="profile.billing.plan == 'free-plan'" class="pro-badge" title="Please subscribe to our paid plan to enable this option" tabindex="0">PRO</span> Element Screenshot <span class="beta-badge">BETA</span> <i class="fas fa-info-circle" title="Allow users to highlight an element on your web page when sending their own feedback (not available when upvoting an existing feedback)" tabindex="0"></i></label>
          <div class="radios">
            <label :class="{ selected: widget.settings.screenshot, disabled: profile.billing.plan == 'free-plan' }"><i class="fas fa-camera"></i> Allow Screenshot<input type="checkbox" v-model="widget.settings.screenshot" :disabled="profile.billing.plan == 'free-plan'" @click="handleFup"></label>
          </div>
          <input type="text" v-if="widget.settings.screenshot" v-model="widget.texts.screenshot" placeholder="Text to display on button hover" required style="margin-top: -5px;">
          <label><span v-if="profile.billing.plan == 'free-plan'" class="pro-badge" title="Please subscribe to our paid plan to enable this option" tabindex="0">PRO</span> Branding</label>
          <div class="radios">
            <label :class="{ selected: widget.settings.branding, disabled: profile.billing.plan == 'free-plan' }"><i class="fas fa-copyright"></i> Show Branding<input type="checkbox" v-model="widget.settings.branding" :disabled="profile.billing.plan == 'free-plan'"></label>
          </div>
          <button v-if="route.name == 'Create' && !isLoading">Create Widget</button>
          <button v-if="route.name == 'Edit' && !isLoading">Update Widget</button>
          <button v-if="isLoading" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </form>
      </div>
    </div>
    <div class="rightside">
      <div class="fake-browser-frame" :class="widget.settings.position == 'right' ? 'middle-right' : 'middle-left'">
				<div class="fadein"></div>
				<div class="top">
					<div class="fakeBtns"></div>
					<div class="fakeBtns"></div>
					<div class="fakeBtns"></div>
          <div class="fakeTitle">Widget Preview</div>
					<div class="burger"> 
						<div></div> 
						<div></div> 
						<div></div> 
					</div>
				</div>
				<div class="widget-preview-holder">
          <div class="emojise-widget-embed" :class="[{ opened: openWidget }, widget.settings.embed, widget.settings.embed == 'float' ? widget.settings.position : '']">
            <div class="widget">
              <div class="feedback-container">
                <a class="close" @click="openWidget = false" :style="{ 'background-color': widget.settings.mainColour, color: widget.settings.textColour }">X</a>
                <div class="main-message">
                  <p class="title">{{ widget.texts.title }}</p>
                  <p v-if="widget.texts.subtitle" class="subtitle">{{ widget.texts.subtitle }}</p>
                </div>
                <div class="ratings">
                  <img v-for="(emoji, index) in widget.emojis" :key="index" :src="emoji.emojiURL" class="emoji">
                </div>
                <div class="follow-up-container" :class="{ shown: showFup, rounded: !widget.settings.branding }">
                  <form @submit.prevent>
                    <textarea class="follow-up" :class="{ 'show-btn': (widget.settings.screenshot) }" :disabled="votedEl" :value="responseText" :placeholder="fupData" @input="handleForm"></textarea>
                    <div class="screenshot" :class="{ 'show-btn': (widget.settings.screenshot), 'disabled': votedEl }">
                      <div class="icon"></div>
                      <div class="screenshot-title">{{ widget.texts.screenshot }}</div>
                    </div>
                    <div v-if="widget.settings.votes" class="voting-section">
                      <p class="vote-mssg">{{ widget.texts.votes }}</p>
                      <div class="follow-up vote-list">
                        <div class="vote-item" id="vote-1" :class="{ 'selected-vote': (votedEl == 'vote-1') }" @click="handleForm">
                          <div class="vote-count">
                            <div class="vote-counter" title="Votes" tabindex="0">
                              <span v-if="votedEl != 'vote-1'">21</span>
                              <span v-else>22</span>
                            </div>
                          </div>
                          <article class="feedback-item">
                            <div class="feedback-rating">
                              <img src="https://firebasestorage.googleapis.com/v0/b/emojise-app.appspot.com/o/emojis%2Fpublic%2Femoji-263.png?alt=media&token=1c5bcaf2-f88e-445f-8a79-e50b34a2a43f">
                            </div>
                            <div class="feedback-response">
                              <span>Love your logo!</span>
                              <div class="feedback-screenshot"></div>
                            </div>
                          </article>
                        </div>
                        <div class="vote-item" id="vote-2" :class="{ 'selected-vote': (votedEl == 'vote-2') }" @click="handleForm">
                          <div class="vote-count">
                            <div class="vote-counter" title="Votes" tabindex="0">
                              <span v-if="votedEl != 'vote-2'">10</span>
                              <span v-else>11</span>
                            </div>
                          </div>
                          <article class="feedback-item">
                            <div class="feedback-rating">
                              <img src="https://firebasestorage.googleapis.com/v0/b/emojise-app.appspot.com/o/emojis%2Fpublic%2Femoji-263.png?alt=media&token=1c5bcaf2-f88e-445f-8a79-e50b34a2a43f">
                            </div>
                            <div class="feedback-response">
                              <span>Your website looks great</span>
                            </div>
                          </article>
                        </div>
                        <div class="vote-item" id="vote-3" :class="{ 'selected-vote': (votedEl == 'vote-3') }" @click="handleForm">
                          <div class="vote-count">
                            <div class="vote-counter" title="Votes" tabindex="0">
                              <span v-if="votedEl != 'vote-3'">8</span>
                              <span v-else>9</span>
                            </div>
                          </div>
                          <article class="feedback-item">
                            <div class="feedback-rating">
                              <img src="https://firebasestorage.googleapis.com/v0/b/emojise-app.appspot.com/o/emojis%2Fpublic%2Femoji-263.png?alt=media&token=1c5bcaf2-f88e-445f-8a79-e50b34a2a43f">
                            </div>
                            <div class="feedback-response">
                              <span>Fantastic service!</span>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                    <button class="send" :style="{ 'background-color': widget.settings.mainColour, color: widget.settings.textColour }" :disabled="(!votedEl && (!responseText || responseText.length < 5))">{{ widget.texts.submit }}</button>
                  </form>
                </div>
                <div class="thanks-message">
                  <p class="thanks-text">{{ widget.texts.thanks }}</p>
                </div>
                <p class="branding" v-if="widget.settings.branding">
                  <a href="https://emojise.com" target="_blank">⚡ by Emojise</a>
                </p>
              </div>
            </div>
            <div class="main-button" @click="openWidget = true" :style="{ 'background-color': widget.settings.mainColour }">
              <span :style="{ color: widget.settings.textColour }">{{ widget.texts.button }}</span>
            </div>
          </div>
				</div>
			</div>
    </div>
  </div>
  <EmojiModal v-if="loadEmojiModal" :class="{ show: showEmojis }" @emoji-change="handleEmojiChange" @modal-close="showEmojis = false" />
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import getCollection from '../../composables/getCollection'
import EmojiModal from './EmojiModal'
import isValidDomain from 'is-valid-domain'

export default {
  props: ['widgetObj', 'isLoading'],
  components: { EmojiModal },
  emits: ['form-submit'],
  setup(props, { emit }) {
    const route = useRoute()
    const { profile } = getCollection()
    const widget = ref(props.widgetObj)
    const openWidget = ref(false)
    const showFup = ref(false)
    const fupDataType = ref('posData')
    const responseText = ref(null)
    const votedEl = ref(null)
    const domainError = ref(false)
    const loadEmojiModal = ref(false)
    const showEmojis = ref(false)
    let emojiIndex = null
    
    const fupData = computed(() => {
      if (fupDataType.value === 'posData') {
        return widget.value.texts.posQn
      } else if (fupDataType.value === 'negData') {
        return widget.value.texts.negQn
      }
    })
    
    const handleDomain = e => {
      if (isValidDomain(e.target.value, { subdomain: true, allowUnicode: true })) {
        domainError.value = false
      } else {
        domainError.value = true
      }
    }

    const handleEmojiNumber = (e) => {
      widget.value.settings.reversed = false
      
      if (e.target.value == 5) {
        widget.value.emojis = {
          0: {
            emoji: 'emoji-263',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-263.png',
            type: 'positive',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          },
          1: {
            emoji: 'emoji-166',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-166.png',
            type: 'positive',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          },
          2: {
            emoji: 'emoji-120',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-120.png',
            type: 'neutral',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          },
          3: {
            emoji: 'emoji-269',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-269.png',
            type: 'negative',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          },
          4: {
            emoji: 'emoji-102',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-102.png',
            type: 'negative',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          }
        }
      } else if (e.target.value == 3) {
        widget.value.emojis = {
          0: {
            emoji: 'emoji-263',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-263.png',
            type: 'positive',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          },
          1: {
            emoji: 'emoji-120',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-120.png',
            type: 'neutral',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          },
          2: {
            emoji: 'emoji-102',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-102.png',
            type: 'negative',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          }
        }
      } else if (e.target.value == 2) {
        widget.value.emojis = {
          0: {
            emoji: 'emoji-263',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-263.png',
            type: 'positive',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          },
          1: {
            emoji: 'emoji-102',
            emojiURL: 'https://app.emojise.com/assets/emojis/emoji-102.png',
            type: 'negative',
            votes: 0,
            followUps: 0,
            statusCount: {
              new: 0,
              working: 0,
              completed: 0
            }
          }
        }
      }
    }

    const handleEmojiClick = (e) => {
      emojiIndex = e.target.getAttribute('data-index')
      showEmojis.value = true
    }

    const handleEmojiChange = (emoji) => {
      widget.value.emojis[emojiIndex].emoji = emoji.id
      widget.value.emojis[emojiIndex].emojiURL = emoji.src
      showEmojis.value = false
      emojiIndex = null
    }

    const handleEmojiFlip = () => {
      widget.value.emojis = { ...Object.values(widget.value.emojis).reverse() }
      widget.value.settings.reversed = !widget.value.settings.reversed
    }

    const handlePageAdd = () => {
      widget.value.settings.exclPages.push(null)
    }

    const handlePageRemove = index => {
      widget.value.settings.exclPages.splice(index, 1)
    }

    const handleFup = e => {
      showFup.value = true

      if (e.target.id === 'positive-follow-up') {
        fupDataType.value = 'posData'
      } else if (e.target.id === 'negative-follow-up') {
        fupDataType.value = 'negData'
      }
    }

    const handleForm = e => {
      if (e.type == 'input') {
        if (votedEl.value) {
          votedEl.value = null
        }
        responseText.value = e.target.value
      } else {
        if (e.target.id == votedEl.value) {
          votedEl.value = null
          responseText.value = null
        } else {
          votedEl.value = e.target.id
          responseText.value = null
        }
      }
    }

    const handleSubmit = e => {
      if (domainError.value) {
        window.scrollTo({top: 100, behavior: 'smooth'});
      } else {
        emit('form-submit', e, widget.value)
      }
    }

    onMounted(() => {
      loadEmojiModal.value = true
    })

    return { route, profile, widget, openWidget, showFup, fupData, votedEl, responseText, 
      domainError, loadEmojiModal, showEmojis, handleDomain, handleEmojiNumber, handleEmojiClick, 
      handleEmojiChange, handleEmojiFlip, handlePageAdd, handlePageRemove, handleFup, handleForm, 
      handleSubmit }
  }
}
</script>

<style scoped>
  .container {
    display: flex;
    padding: 30px 50px;
  }
  .leftside {
    width: 42%;
  }
  .page-title {
    text-align: center;
    margin-bottom: 10px;
  }
  form {
    max-width: 500px;
    padding: 20px;
  }
  label {
    font-weight: bold;
  }
  input {
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  }
  select {
    width: 100%;
    margin: 5px auto 20px auto;
    border: none;
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  select:disabled {
    cursor: not-allowed;
  }
  hr {
    margin: 30px 0 25px 0;
    border: none;
    border-top: 1px solid var(--border);
  }
  .emoji-set {
    display: flex;
    width: max-content;
    margin: 10px 0 0 0;
  }
  .emoji-set:hover > .emoji-type .emoji {
    opacity: 0.5;
  }
  .emoji-set:hover > .emoji-type .emoji:hover {
    opacity: 1;
  }
  .emoji-set .emoji-type {
    width: 60px;
    margin: 0 5px;
    text-align: center;
  }
  .emoji-set .emoji-type:first-child {
    margin-left: 0;
  }
  .emoji-set .emoji-type:last-child {
    margin-right: 0;
  }
  .emoji-set .emoji-type span {
    font-size: 12px;
  }
  .emoji {
    margin: 0 3px;
  }
  .radios {
    display: flex;
    width: 100%;
    margin: 10px auto 20px auto;
  }
  .radios label {
    cursor: pointer;
    padding: 7px;
    color: var(--main);
    background-color: #ffffff;
    border: 1px solid;
    border-radius: 7px;
    margin-right: 10px;
    font-weight: bold;
  }
  .radios input[type=radio], .radios input[type=checkbox] {
    position: absolute;
    width: 0;
    opacity: 0;
    pointer-events: none;
  }
  .radios i {
    color: var(--main);
    margin-right: 5px;
  }
  .radios .selected {
    color: #ffffff;
    background-color: var(--main);
    border-color: var(--main);
  }
  .radios .disabled {
    cursor: not-allowed;
  }
  .radios .selected i {
    color: #ffffff;
  }
  .colour-pick {
    display: flex;
    justify-content: space-between;
    margin: 10px auto 20px auto;
  }
  .colour-pick input {
    padding: 0;
    margin-bottom: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .exclude-pages {
    display: flex;
    justify-content: space-between;
  }
  .exclude-pages input {
    min-width: 85%;
    height: max-content;
    margin: 5px auto;
  }
  .exclude-pages .btn {
    background-color: red;
    height: max-content;
    margin: 8px 0 0 10px;
  }
  button {
    display: block;
    margin: 30px 0 0 auto;
    font-size: 16px;
  }
  .btn {
    margin: 10px 0 20px 0;
  }
  .beta-badge {
    display: inline-block;
    padding: 3px 5px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    margin-top: 1px;
    vertical-align: top;
    border-radius: 0.25rem;
    color: rgb(255, 255, 255);
    background-color: var(--link);
  }
  .pro-badge {
    display: inline-block;
    padding: 3px 5px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    margin-top: 1px;
    vertical-align: top;
    border-radius: 0.25rem;
    color: rgb(255, 255, 255);
    background-color: #efb60c;
    cursor: help;
  }
  .rightside {
    position: fixed;
    width: 50%;
    height: calc(100vh - 70px);
    bottom: 0;
    right: 0;
    background-color: #fafad2;
  }
  .rightside .page-title {
    margin: 30px 0 0 0;
  }
  .fake-browser-frame {
    background: #fff;
    min-width: 200px;
    max-width: 500px;
    height: auto;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 2px rgba(0,0,0,.24),0 1px 3px rgba(0,0,0,.12);
    margin: 0 auto;
    position: relative;
    top: 13px;
  }
  .fake-browser-frame .top {
    background: #e6e6e6;
    padding: 5px 12px 0;
    border-bottom: 1px solid #e6e6e6;
    height: 35px;
    text-align: center;
    border-radius: 4px 4px 0 0;
  }
  .fake-browser-frame .fadein {
    position: absolute;
    top: -8px;
    bottom: -8px;
    width: 110px;
    background: -webkit-gradient(left top,right top,color-stop(0,#FAFAD2),color-stop(17%,#FAFAD2),color-stop(100%,transparent));
    background: linear-gradient(90deg,#FAFAD2 0,#FAFAD2 25%,transparent);
  }
  .fake-browser-frame[class*="left"] .fadein {
    right: -8px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
  .fake-browser-frame[class*="right"] .fadein {
    left: -8px;
  }
  .fake-browser-frame[class*="left"] .top .fakeBtns {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ccc;
    margin-right: 6px;
    margin-top: 8px;
    float: left;
  }
  .fake-browser-frame .top .fakeTitle {
    display: inline-block;
    padding-top: 1px;
    color: rgb(160, 160, 160);
    font-weight: 600;
  }
  .fake-browser-frame .top > .burger {
    float: right;
    width: 16px;
    height: 20px;
    border-radius: 0;
    background: transparent;
    line-height: 2px;
    margin: 2px 0 0;
  }
  .fake-browser-frame .top > .burger > div {
    display: inline-block;
    width: 100%;
    height: 3px;
    background: #ccc;
    border-radius: 5px;
  }
  .widget-preview-holder {
    height: 500px;
    position: relative;
    overflow: hidden;
  }
  .emojise-widget-embed * {
    font-family: Arial, sans-serif;
    letter-spacing: 0.5px;
    margin: 0;
    color: #4f515a;
  }
  .emojise-widget-embed {
    position: relative;
    top: 50%;
    display: flex;
    width: 320px;
    z-index: 990;
    box-sizing: border-box;
    transform: translateY(-50%);
    height: 150px;
  }
  .emojise-widget-embed .main-button {
    position: absolute;
    width: 42px;
    height: 110px;
    padding: 5px;
    box-shadow: 0 2px 6px 1px rgba(0,0,0,0.5);
    background-color: var(--main);
    text-align: center;
    cursor: pointer;
  }
  .emojise-widget-embed .main-button:hover {
    box-shadow: 0 6px 15px 1px rgba(0, 0, 0, 0.5);
    transform: scale(1.02);
  }
  .emojise-widget-embed .main-button span {
    position: relative;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    writing-mode: vertical-rl;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
  }
  .emojise-widget-embed .feedback-container {
    position: relative;
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    border-radius: 10px;
    border: none;
    box-shadow: rgba(0,0,0,0.4) 0 6px 50px 0;
    text-align: center;
    transition: opacity 1s;
  }
  .emojise-widget-embed .feedback-container .close {
    position: absolute;
    height: 20px;
    width: 20px;
    cursor: pointer;
    color: #ffffff;
    z-index: 9999;
    right: -5px;
    top: -7px;
    border-radius: 50%;
    background-color: var(--main);
    border: none;
    line-height: 20px;
    font-size: 15px;
    font-weight: bold;
    font-family: sans-serif;
    letter-spacing: normal;
  }
  .emojise-widget-embed .main-message {
    padding: 20px 20px 0 20px;
  }
  .emojise-widget-embed .main-message .title {
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    padding: 0 10px;
  }
  .emojise-widget-embed .main-message .subtitle {
    font-size: 14px;
    font-weight: normal;
    padding-top: 10px;
    margin: 0;
  }
  .emojise-widget-embed .ratings {
    display: flex;
    width: max-content;
    margin: 0 auto;
    padding: 20px 0;
  }
  .emojise-widget-embed .emoji {
    width: 40px;
    cursor: pointer;
    margin: 0 5px;
  }
  .emojise-widget-embed .emoji.selected {
    opacity: 1;
  }
  .emojise-widget-embed .ratings:hover > .emoji {
    opacity: 0.5;
  }
  .emojise-widget-embed .ratings:hover > .emoji:hover {
    opacity: 1;
  }
  .emojise-widget-embed .follow-up-container {
    height: 0;
    overflow: hidden;
    padding: 0;
    margin-bottom: 0;
    transition: padding 1s, margin 1s;
  }
  .emojise-widget-embed .follow-up-container.shown {
    background-color: #f0f0f0;
    padding: 20px 10px 10px 10px;
    margin: 0 auto 10px auto;
    height: auto;
  }
  .emojise-widget-embed .follow-up-container.rounded {
    margin: 0 !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .emojise-widget-embed .follow-up-container form {
    background-color: inherit;
    padding: 0;
  }
  .emojise-widget-embed .follow-up-container .follow-up {
    width: 90%;
    margin: 0 auto 10px auto;
    padding: 8px 10px;
    background: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    resize: none;
  }
  .emojise-widget-embed .follow-up-container textarea:disabled, .emojise-widget-embed .follow-up-container .screenshot.disabled .icon, .emojise-widget-embed .follow-up-container .send:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .emojise-widget-embed .follow-up-container textarea.show-btn {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }
  .emojise-widget-embed .follow-up-container .screenshot {
    display: none;
    width: 90%;
    height: 32px;
    margin: 0px auto 12px auto;
    padding: 5px 5px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
    background: #fff;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 4px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .emojise-widget-embed .follow-up-container .screenshot.show-btn {
    display: block;
  }
  .emojise-widget-embed .follow-up-container .screenshot .icon {
    width: 23px;
    height: 23px;
    float: right;
    cursor: pointer;
    background: url('https://app.emojise.com/assets/images/selection.svg') no-repeat center/cover;
  }
  .emojise-widget-embed .screenshot-title {
    visibility: hidden;
    width: 150px;
    height: auto;
    position: absolute;
    background: var(--primary);
    border-radius: 10px;
    padding: 5px 10px;
    right: 60px;
    margin-top:-10px;
    font-size: 14px;
    color: #fff;
    z-index: 1;
    text-align: center;
    letter-spacing: initial;
  }
  .emojise-widget-embed .follow-up-container .screenshot .icon:hover + .screenshot-title {
    visibility: visible;
  }
  .emojise-widget-embed .follow-up-container .voting-section .vote-mssg {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .emojise-widget-embed .follow-up-container .follow-up.vote-list {
    max-height: 100px;
    overflow: auto;
  }
  .emojise-widget-embed .follow-up.vote-list .vote-item {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid var(--border);
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }
  .emojise-widget-embed .follow-up.vote-list .vote-item * {
    pointer-events: none;
  }
  .emojise-widget-embed .follow-up.vote-list .vote-item:hover {
    background-color: var(--border);
  }
  .emojise-widget-embed .follow-up.vote-list .vote-item.selected-vote {
    background-color: var(--main);
  }
  .emojise-widget-embed .follow-up.vote-list .vote-count {
    margin: 7px 7px 0 0px !important;
  }
  .emojise-widget-embed .follow-up.vote-list .vote-counter {
    min-width: 25px;
    padding: 2px 4px;
    border: 1px solid #fff0f0;
    background: #fff0f0;
    border-radius: 5px;
  }
  .emojise-widget-embed .follow-up.vote-list .vote-counter span {
    font-size: 12px;
    font-weight: 600;
  }
  .emojise-widget-embed .follow-up.vote-list .feedback-item {
    display: flex;
    justify-content: center;
    width: 220px;
    padding: 0;
    border: none;
  }
  .emojise-widget-embed .follow-up.vote-list .feedback-rating img {
    width: 35px;
  }
  .emojise-widget-embed .follow-up.vote-list .feedback-response {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    position: relative;
    color: var(--primary);
    letter-spacing: initial;
    padding: 8px;
    background: #fff0f0;
    border-radius: 3px;
    border-right: none;
    font-size: 12px;
    line-height: 1;
    width: 100%;
    text-align: left;
  }
  .emojise-widget-embed .follow-up.vote-list .feedback-response span {
    font-size: 12px;
    letter-spacing: initial;
    line-height: 1.2;
  }
  .emojise-widget-embed .follow-up.vote-list .feedback-screenshot {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-left: 5px;
    box-shadow: 0 1px 2px 2px rgb(0 0 0 / 20%);
    border-radius: 5px;
    background: url('../../assets/images/emojise-logo-app.png') no-repeat center/cover;
  }
  .emojise-widget-embed .follow-up-container .send {
    width: 65%;
    margin: 17px auto 10px auto;
    padding: 7px 10px;
    border-radius: 5px;
    border: none;
    background-color: var(--main);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    opacity: 1;
  }
  .emojise-widget-embed .follow-up-container .send:not(:disabled):hover {
    opacity: 0.8;
  }
  .emojise-widget-embed .thanks-message {
    display: none;
    padding: 10px;
    font-weight: 600;
  }
  .emojise-widget-embed .branding {
    position: relative;
    bottom: 5px;
    right: 10px;
    color: #696969;
    font-size: 11px;
    line-height: 1.8;
    text-align: right;
  }
  .emojise-widget-embed .branding a {
    text-decoration: none;
    color: inherit;
  }
  .emojise-widget-embed.left {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: unset;
    float: left;
  }
  .emojise-widget-embed.left .main-button {
    right: unset;
    left: 0;
    border-radius: 0 5px 5px 0;
  }
  .emojise-widget-embed.left .feedback-container {
    left: 30px;
  }
  .emojise-widget-embed.right {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    left: unset;
    right: 0;
    float: right;
  }
  .emojise-widget-embed.right .main-button {
    left: unset;
    right: 0;
    border-radius: 5px 0 0 5px;
  }
  .emojise-widget-embed.right .feedback-container {
    right: 30px;
  }
  .emojise-widget-embed.inline {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    text-align: center;
  }
  .emojise-widget-embed.inline .main-button {
    display: none;
  }
  .emojise-widget-embed.inline .widget {
    display: contents;
  }
  .emojise-widget-embed.inline .feedback-container {
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    background: transparent;
  }
  .emojise-widget-embed.inline .close {
    visibility: hidden !important;
  }
  .emojise-widget-embed.inline .follow-up-container {
    border-radius: 5px;
  }
  .emojise-widget-embed.opened {
    height: auto;
  }
  .emojise-widget-embed.opened .main-button {
    display: none;
  }
  .emojise-widget-embed.opened .feedback-container {
    visibility: visible;
    opacity: 1;
  }
  .emojise-widget-embed.opened .close {
    visibility: visible;
  }

  @media (max-width: 900px) {
    
    .container {
      display: block;
      padding: 0;
    }
    .leftside, .rightside {
      width: 100%;
    }
    .leftside {
      padding: 20px;
    }
    .rightside {
      position: relative;
      height: auto;
      padding: 5px 20px 50px 20px !important;
    }
    .fake-browser-frame {
      top: 20px;
    }
    .emoji-set .emoji-type {
      width: 50px;
    }

  }

  @media (max-width: 500px) {
    
    .fake-browser-frame .fadein {
      display: none;
    }
    .emojise-widget-embed {
      width: 100% !important;
    }
    .emojise-widget-embed .widget {
      width: 100% !important;
    }
    .emojise-widget-embed .feedback-container {
      left: unset !important;
      right: unset !important;
      width: calc(100% - 30px) !important;
      max-width: 320px !important;
      margin: 0 auto !important;
    }

  }
</style>