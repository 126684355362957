<template>
  <div class="modal">
    <div class="inner-modal">
      <div class="navbar">
        <div class="tab">
          <div class="tablink" :class="{ active: (curTab == 'default') }" @click="curTab = 'default'">Emojise Set</div>
          <div class="tablink" :class="{ active: (curTab == 'upload') }" @click="curTab = 'upload'">Upload Your Own</div>
        </div>
        <span class="close" @click="handleClose">&times;</span>
      </div>
      <div class="emoji-list" :class="{ shown: (curTab == 'default') }">
        <img v-for="image in images" :key="image.id" :id="image.id" :src="image.src" class="emoji" alt="Emoji" @click="handleClick" />
      </div>
      <div class="upload-section" :class="{ shown: (curTab == 'upload') }">
        <form @submit.prevent="handleUpload">
          <input type="file" accept="image/*" @change="handleChange">
          <span class="upload-warning">Max file size is 2MB.<br>Only image files (jpg, png and svg) are allowed.</span>
          <div class="error">{{ fileError }}</div>
          <button v-if="!isLoading">Upload</button>
          <button v-else disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import useStorage from '../../composables/useStorage'

export default {
  emits: ['emoji-change', 'modal-close'],
  setup(props, { emit }) {
    const { url, error, uploadImage } = useStorage()
    const images = ref([])
    const curTab = ref('default')
    const file = ref(null)
    const fileError = ref(null)
    const isLoading = ref(false)

    const loadImages = async () => {
      for (let i = 1; i < 307; i++) {
        images.value.push({
          id: `emoji-${i}`,
          src: `https://app.emojise.com/assets/emojis/emoji-${i}.png`
        })
      }
    }
    
    const handleClick = e => {
      emit('emoji-change', { id: e.target.id, src: e.target.src })
    }

    const handleChange = (e) => {
      const types = ['image/png', 'image/jpeg', 'image/svg+xml']
      const maxSize = 2 * 1024 *1024
      const selected = e.target.files[0]

      if (selected && types.includes(selected.type)) {
        file.value = selected
        fileError.value = null
      } else if (selected && selected.size > maxSize) {
        file.value = null
        fileError.value = 'Please select an image file (png, jpg or svg) smaller than 2MB'
      } else {
        file.value = null
        fileError.value = 'Please select an image file (png, jpg or svg)'
      }
    }

    const handleUpload = async () => {
      if (file.value) {
        isLoading.value = true
        await uploadImage(file.value)  
        isLoading.value = false

        if (!error.value) {
          const lastIndex = file.value.name.lastIndexOf('.')
          emit('emoji-change', { id: file.value.name.substr(0, lastIndex), src: url.value })
          curTab.value = 'default'
        }
      }
    }

    const handleClose = () => {
      emit('modal-close')
    }

    onMounted(() => {
      loadImages()
    })

    return { images, curTab, fileError, isLoading, handleClick, handleChange, 
      handleUpload, handleClose }
  }
}
</script>

<style scoped>
  .inner-modal {
    width: 90%;
    border: none;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
  }
  .navbar .close {
    padding-top: 10px;
  }
  .navbar .tab {
    justify-content: left;
  }
  .navbar .tab .tablink {
    width: auto;
    background-color: #fff;
    color: var(--primary);
    border: 1px solid var(--border);
  }
  .navbar .tab .tablink.active {
    background-color: var(--main);
    color: #fff;
  }
  .navbar .tab .tablink:hover {
    background-color: var(--main-hover);
    color: #fff;
  }
  .emoji-list, .upload-section {
    display: none;
  }
  .emoji-list.shown, .upload-section.shown {
    display: block;
  }
  .emoji {
    width: 40px;
    margin: 5px;
  }
  .emoji:hover {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin: 0px !important;
  }
  .upload-section {
    padding: 20px;
  }
  .emoji-list, form {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.2);
  }
  form button {
    margin: 10px 0;
  }
  .upload-warning {
    font-style: italic;
  }
</style>